import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BannerInside from '../components/Bannerinside/Bannerinside';


function Copyright(){
    return(
        <div className='innerPageBgBlock'>
            <BannerInside PageTitle="Copyright Notice"></BannerInside>
            <section className='innerPageDetails'>
                <Container>
                    <p>All contents published on the <Link to="/">Edfora</Link> (hereinafter the “website”), including text, photograph, image, designs, color combinations, graphics, scripts, audio, video and other materials, are subject to the protection of the Copyright Act. Copyright law prohibits the reproduction or transmission in any form or by any means, whether mechanical, photographic or electronic, of any portion of this publication without the express permission of Edfora.<br/><br/>
                    All material available on this website is protected by copyright laws. (Depicted as © <span className='linkText'>Edfora Infotech Private Limited, All rights reserved, also referred as Edfora).</span> This “Copyright Disclaimer” should be at all times read along with the “Terms of Service” of the website. Access to our services and content is subject to terms of service, which you have accepted during the registration process. Please refer to terms of service and privacy policy; in case you have any doubt on any particular terms of service. We grant you a limited license to access and use, and to download or print a copy of any portion of the site content, other than third party content, to which you have properly gained access and to reproduce copies of the material strictly for your personal, non-commercial use, provided that you keep all copyright or other proprietary notices intact. Such license is subject to the terms of service and does not include use of any data mining, robots or similar data gathering or extraction methods. While no pecuniary consideration is required for this permission, we require the following statement to be included in all reproductions of the material. <span className='linkText'>“© Edfora Infotech Private Limited, All rights reserved. Reprinted with the permission of EDFORA INFOTECH PRIVATE LIMITED”.</span><br/><br/>
                    You are also not supposed to use any part of the material including graphics or logos available on the website for the purpose of copying, photocopying, reproduction, translation or reduction to any electronic medium or machine readable form, either in whole or in part, without prior written consent of the owner. Any reproduction of our content or material is prohibited in any form without our prior written permission. Distribution of the material from the website for commercial purposes is prohibited. Domestic and International copyright and Trademark laws protect the entire contents of the site. The owners of the intellectual property, copyrights and trademarks are EDFORA INFOTECH PRIVATE LIMITED, its affiliates or third party licensors.<br/><br/>
                    You are expressly prohibited from modifying, copying, reproducing, republishing, uploading, posting, transmitting or distributing any material on this site including text, graphics, code and/or software.<br/><br/>
                    You also agree that you shall not take recourse against the owner for any alleged or actual infringement or misappropriation of any proprietary rights in your communications to the owner. If you, at any stage, believe that the contents appearing on this site constitutes copyright infringement of another person’s rights, please communicate the same to us. Owner reserves the right to remove any content or links that allegedly infringes any other person’s copyright at any point of time. Notice to owner regarding any alleged copyright infringement shall be directed at:
                    </p>
                    <h5 className='subText'>CEO</h5>
                    <h5 className='subText lssmall'><span className='fLight'>EDFORA INFOTECH PRIVATE LIMITED<br/> 32-A, Chaudhary House, Opp Vijay Mandal Enclave Gate no 1,<br/>Kalu Sarai, Hauz Khas, New Delhi 110016</span></h5>
                    <p>Please take note that some contents on the website belong to a third party. Such third party contents have been reproduced after taking prior permission from the said party and the copyright of such contents would remain exclusively with the said third party. Owner shall not be responsible for any mistakes, which might appear in such contents. Third party contents may also be accessible via links, we do not claim any copyright on the contents appearing on such third party sites nor shall be held responsible for any mistakes, which might appear therein.<br/><br/>
                    Any request for any reprint or other permission should be mailed to:<br/><br/>
                    <a className='linkUnderline' href="mailto:support@edfora.com">support@edfora.com</a>
                    </p>
                    <h5 className='subText lssmall'>EDFORA INFOTECH PRIVATE LIMITED<br/>
                        <span className='fLight'>32-A, Chaudhary House, Opp Vijay Mandal Enclave Gate no 1,<br/>Kalu Sarai, Hauz Khas, New Delhi 110016</span></h5>
                </Container>
            </section>
        </div>
    )
}

export default Copyright;