import { Col, Container, Dropdown, Form, NavLink, Row } from 'react-bootstrap';
import './contact.scss';
import BannerInside from '../components/Bannerinside/Bannerinside';
import React, { useState } from 'react';
import axios from '../services/axios';

function Contact() {
  const errorMap = { emailErr: false, nameErr: false, mobileErr: false, descriptionErr: false };
  const descriptionInfo = { value: '', count: 0 };
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [description, setDescription] = useState({ ...descriptionInfo });
  const [mobile, setMobile] = useState('');
  const [mobileCode, setMobileCode] = useState('+91');
  const [country, setCountry] = useState('/images/country-flags/india.png');
  const [enquiry, setEnquiry] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [isSubmitted, setFormSubmit] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [errorState, setErrorState] = useState({ ...errorMap });
  const mobileCodeDropDown = [
    { name: 'India', code: '+91', image: '/images/country-flags/india.png' },
    { name: 'Nepal', code: '+977', image: '/images/country-flags/nepal.png' },
    { name: 'Saudi Arabia', code: '+966', image: '/images/country-flags/saudi-arabia.png' },
    { name: 'Malaysia', code: '+60', image: '/images/country-flags/malaysia.png' },
    { name: 'United Arab Emirates', code: '+971', image: '/images/country-flags/united-arab-emirates.png' },
    { name: 'Myanmar', code: '+95', image: '/images/country-flags/myanmar.png' },
    { name: 'Sri Lanka', code: '+94', image: '/images/country-flags/sri-lanka.png' },
    { name: 'Oman', code: '+968', image: '/images/country-flags/oman.png' },
    { name: 'Singapore', code: '+65', image: '/images/country-flags/singapore.png' },
    { name: 'Bahrain', code: '+973', image: '/images/country-flags/bahrain.png' },
    { name: 'Qatar', code: '+974', image: '/images/country-flags/qatar.png' },
    { name: 'South Africa', code: '+27', image: '/images/country-flags/south-africa.png' },
    { name: 'United States Of America', code: '+1', image: '/images/country-flags/united-states-of-america.png' },
  ];
  const enquiryType = [
    { name: 'General' },
    { name: 'Business' },
  ];

  function handleName(e: any) {
    setName(e.target.value);
  }

  function handleNameValidation(e: any) {
    const value = e.target.value;
    errorMap.nameErr = false;
    const regex = /[^A-Z a-z]/;
    if (regex.test(value)) {
      errorMap.nameErr = true;
    }
    setErrorState((prev) => ({ ...prev, nameErr: errorMap.nameErr }));
  }

  function handleEmail(e: any) {
    setEmail(e.target.value);
  }

  function handleEmailValidation(e: any) {
    const value = e.target.value;
    errorMap.emailErr = false;
    if (!value.includes('@')) {
      errorMap.emailErr = true;
    }
    setErrorState((prev) => ({ ...prev, emailErr: errorMap.emailErr }));
  }

  function handleOrganization(e: any) {
    setOrganization(e.target.value);
  }

  function handleDescriptionValidation(e: any) {
    const value = e.target.value;
    errorMap.descriptionErr = false;
    if (value.length < 10) {
      errorMap.descriptionErr = true;
    }
    setErrorState((prev) => ({ ...prev, descriptionErr: errorMap.descriptionErr }));
  }

  function handleDescription(e: any) {
    let value = e.target.value;
    errorMap.descriptionErr = false;
    if (value.length > 2000) {
      value = value.substring(0, 2000);
    }
    descriptionInfo.count = value.length;
    descriptionInfo.value = value;
    setErrorState((prev) => ({ ...prev, descriptionErr: errorMap.descriptionErr }));
    setDescription({ ...descriptionInfo });
  }

  function handleMobileCode(code: string, country: string) {
    setMobileCode(code);
    setCountry(country);
  }

  function handleMobile(e: any) {
    let value = e.target.value;
    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    setMobile(value);
  }

  function handleMobileValidation(e: any) {
    const value = e.target.value;
    errorMap.mobileErr = false;
    if (value.length < 10) {
      errorMap.mobileErr = true;
    }
    setErrorState((prev) => ({ ...prev, mobileErr: errorMap.mobileErr }));
  }

  function handleEnquiryType(type: any) {
    setEnquiry(type);
  }

  function handlePrivacy(e: any) {
    setPrivacy(e.target.checked);
  }

  function closeModal() {
    setModalState(false);
  }

  function handleFormSubmit(e: any) {
    e.preventDefault();
    const isValid = name && !errorState.nameErr && email && !errorState.emailErr && mobile && !errorState.mobileErr && enquiry && description.value && !errorState.descriptionErr;

    if (isValid) {
      const url = '/v4/send-email';
      const payload = {
        name,
        emailId: email,
        contact: mobileCode + ' ' + mobile,
        enquiryType: enquiry,
        message: description
      };

      axios.post(url, payload)
        .then(() => {
          setName('');
          setEmail('');
          setMobile('');
          setOrganization('');
          descriptionInfo.value = ''
          descriptionInfo.count = 0;
          setDescription(descriptionInfo);
          setPrivacy(false);
          setMobileCode('+91');
          setCountry('/images/country-flags/india.png');
          setFormSubmit(false);
          setModalState(true);
        }).catch(() => { });
    }
    setFormSubmit(true);
  }

  return (
    <div className='App'>
      <div className='ContactUs'>
        <BannerInside PageTitle="We love to respond…" PageSubTitle="Let us know you better"></BannerInside>
        <Container className='contentArea'>
          <div className="boxTop pa-0 radius20">
            <Row className='contactMobileView'>
              <Col xs={12} lg={5} xl={4}>
                <div className='contactInfo'>
                  <h1 className='headingTxt'>Contact Information</h1>
                  <p className='info'>
                    <img src='/images/map-pin.svg' alt='map' />
                    32-A, Chaudhary House,<br />
                    Opp Vijay Mandal Enclave Gate no 1, 
                    Kalu Sarai, Hauz Khas,<br />
                    New Delhi 110016
                  </p>
                  <p className='info'>
                    <a href="tel:+911146024639">
                      <img src='/images/phone-call.svg' alt='phone' />
                      +91 11 4602 4639
                    </a>
                  </p>
                  <p className='info'>
                    <a href="mailto:support@edfora.com">
                      <img src='/images/mail.svg' alt='mail' />
                      <u>support@edfora.com</u>
                    </a>
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={7} xl={8}>
                <div className='contactForm'>
                  <form>
                    <div>
                      <div className='mb-30'>
                        <input className={`form-control ${(name && errorState.nameErr) || (!name && isSubmitted) ? 'formError' : ''}`} type="text" name='username' placeholder="Name" value={name} onChange={handleName} onBlur={handleNameValidation} required />
                        {
                          !name && isSubmitted ? <small className="form-text text-danger d-block text-start">Please enter your name</small> : null
                        }
                        {
                          name && errorState.nameErr ? <small className="form-text text-danger d-block text-start">Please enter a valid name</small> : null
                        }
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-30">
                          <input className={`form-control ${(email && errorState.emailErr) || (!email && isSubmitted) ? 'formError' : ''}`} type="email" name='email' placeholder="Email Address" required value={email} onChange={handleEmail} onBlur={handleEmailValidation} />
                          {
                            !email && isSubmitted ? <small className="form-text text-danger d-block text-start">Please enter your email address</small> : null
                          }
                          {
                            email && errorState.emailErr ? <small className="form-text text-danger d-block text-start">Please enter a valid email address</small> : null
                          }
                        </div>
                        <div className="col-md-6 mb-30">
                          <Form.Group className="mobileDropDown" controlId="exampleForm.ControlInput1">
                            <Dropdown className={`etype ${(mobile && errorState.mobileErr) || (!mobile && isSubmitted) ? 'formError' : ''}`}>
                              <Dropdown.Toggle variant="" id="dropdown-basic">
                                <img src={country} alt='India' /> {mobileCode}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {mobileCodeDropDown.map((item, i) => <Dropdown.Item href="#" onClick={() => handleMobileCode(item.code, item.image)} key={i}><img src={item.image} alt={item.name} />{item.code}</Dropdown.Item>)}
                              </Dropdown.Menu>
                            </Dropdown>
                            <input className={`form-control ${(mobile && errorState.mobileErr) || (!mobile && isSubmitted) ? 'formError' : ''}`} type="number" name='mobile' placeholder="Phone" required value={mobile} onChange={handleMobile} onBlur={handleMobileValidation} />
                          </Form.Group>
                          {
                            !mobile && isSubmitted ? <small className="form-text text-danger d-block text-start">Please enter 10-15 digit mobile number</small> : null
                          }
                          {
                            mobile && errorState.mobileErr ? <small className="form-text text-danger d-block text-start">Please enter a valid mobile number</small> : null
                          }
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-30">
                          <Form.Group className="" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" name="org" placeholder="Organization" required value={organization} onChange={handleOrganization} />
                            {
                              !organization && isSubmitted ? <small className="form-text text-danger d-none text-start">Please enter organization</small> : null
                            }
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-30">
                          <Dropdown className={`etype ${!enquiry && isSubmitted ? 'formError' : ''}`}>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                              {enquiry || 'Enquiry Types'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {enquiryType.map((item, i) => <Dropdown.Item href="#" key={i} onClick={() => handleEnquiryType(item.name)}>{item.name}</Dropdown.Item>)}
                            </Dropdown.Menu>
                          </Dropdown>
                          {
                            !enquiry && isSubmitted ? <small className="form-text text-danger d-block text-start">Please select enquiry type</small> : null
                          }
                        </div>
                      </div>
                      <div className='textarea-container mb-30'>
                        <textarea className={`form-control textArea ${(description.value && errorState.descriptionErr) || (!description.value && isSubmitted) ? 'formError' : ''}`} rows={3} name="message" value={description.value} placeholder='Message' onChange={handleDescription} onBlur={handleDescriptionValidation} required>
                        </textarea>
                        {
                          !description.value && isSubmitted ? <small className="form-text text-danger d-block text-start">Please enter your message</small> : null
                        }
                        {
                          description.value && errorState.descriptionErr ? <small className="form-text text-danger d-block text-start">Please enter atleast 10 characters</small> : null
                        }
                        <span className='textCount'>({description.count}/2000)</span>
                      </div>
                      <p className='captchaInfo'>This page is protected by Google reCAPTCHA to ensure you're not a bot.</p>
                      <div className='form-check mtb-30'>
                        <input className='form-check-input' type='checkbox' checked={privacy} onChange={handlePrivacy} />
                        <label className='form-check-label' htmlFor="agree">I agree with the <NavLink href='/privacypolicy'>Privacy Policy</NavLink></label>
                        {
                          !privacy && isSubmitted ? <small className="form-text text-danger d-none text-start">Please select agreement</small> : null
                        }
                      </div>
                      <button type="submit" className="btn btn-primary mt-3" disabled={!privacy} onClick={handleFormSubmit}>Submit</button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {
        modalState ?
          <div className="thankYouModalSec">
            <div className="thankYouModal text-center">
              <div className="thankyouIcon"><img src='/images/like-thanks.png' alt='Like' /></div>
              <h2 className="thankyouTitle">Thank you</h2>
              <p className="thankyouDesc mb-0">This information will enable us to route your request to the appropriate person. <br />We will get in touch with you soon</p>
              <div className="thankyouOkCta" onClick={closeModal}>Okay</div>
            </div>
          </div> : ''
      }
    </div>
  )
}

export default Contact;